import React from 'react';
import Project1 from './1_Project';
import Project2 from './2_Project';
import Project3 from './3_Project';
import Project4 from './4_Project';
import Project5 from './5_Project';
import Project6 from './6_Project';

function body() {
	return (
		<div className="Body">
				<Project1 />
				<Project2 />
				<Project3 />
				<Project4 />
				<Project5 />
				<Project6 />
		</div>
	);
}

export default body;
